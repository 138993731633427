.screen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.contact-line {
  font-size: 1.6rem;
  margin-top: 1rem;
  color: gray;
}

.contact-line a {
  color: black;
}

.expert-word-text-home {
  background-image: linear-gradient(to right, #ff9966, #ff5e62, #00cc99);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

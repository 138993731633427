
.skeleton-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f0f0f0;
}

.skeleton-navbar {
  height: 60px;
  background-color: #e0e0e0;
}

.skeleton-content {
  display: flex;
  flex: 1;
}

.skeleton-sidebar {
  width: 250px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.skeleton-main-content {
  flex: 1;
  padding: 20px;
  display: flex;
  gap: 20px;
}

.skeleton-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.skeleton-card {
  flex: 1;
  min-width: 300px;
  height: 300px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.skeleton-item {
  height: 15px;
  background-color: #f0f0f0;
  border-radius: 4px;
  animation: loading-animation 1s infinite ease-in-out alternate;
}

@keyframes loading-animation {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 1;
  }
}
